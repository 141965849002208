import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ngx-date-view',
  template: `{{value}}`,
})
export class DateViewComponent implements OnInit {
  @Input() value: string;

  constructor() {
  }

  ngOnInit() {
    this.value = moment(this.value).utc().format('YYYY MMM DD HH:mm:ss z');
  }
}
